import React from "react";
import "./JoinAdd.css";
import { getAnalytics, logEvent } from 'firebase/analytics';

export default function JoinAdd({ modalHandler }) {

	const analytics = getAnalytics();

	const handleModal = () => {
		modalHandler(true)
		logEvent(analytics, 'Clicked On Join Today Red Info')

	}

	return (
		<>
			<div className='joinAd flex-column '>
				{/* <div className='NeverBefore d-flex align-items-center justify-content-center'>
					Never Before Offer
				</div> */}
				<div
					style={{ cursor: "pointer" }}
					onClick={() => handleModal()}>
					<img
						src='assets/joinNow249.svg'
						alt=''
						className=' d-none d-sm-inline-block d-md-none d-lg-inline-block' />
					<img
						width="257px"
						src='assets/joinNow249.svg'
						alt=''
						className='  d-sm-none d-md-inline-block d-inline-block d-lg-none'
					/>

					{/* Join Today for just{" "}
					<span className='strikethrough'>350</span>{" "}
					<span style={{ fontSize: "28px" }}>200 </span> AED */}

				</div>
				<p className='firstMonthOff text-center'>
					*For 1st month of online RED Program <br />
					**Valid for new customers only <br />
					***Purchase now and start anytime <br />

				</p>

			</div>
		</>
	);
}
