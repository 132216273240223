// @ts-nocheck
import React from "react";
import ReactPlayer from "react-player/lazy";

export default function ReactPlayerComp({ thumb, url, uid }) {
	console.log(url)
	const handlePlayInline = () => {
		const videos = document.querySelectorAll("video");
		console.log(videos);
		videos.forEach((item) => {
			item.setAttribute("playsinline", "webkit-playsinline");
			item.setAttribute("playsinline", "playsinline");
		});
	};
	const handlePlay = () => {
		const videos = document.querySelectorAll(".ReactPlayer video");
		console.log(videos);
		// videos.forEach((item) => {
		// 	// @ts-ignore
		// 	if (item.parentNode.id !== uid) {
		// 		// console.log(item);
		// 		item.pause();
		// 	} else {
		// 		item.play();
		// 	}
		// 	// item.setAttribute("playsinline", "webkit-playsinline");
		// 	// item.setAttribute("playsinline", "playsinline");
		// });
	};
	return (
		<>
			<ReactPlayer
				light={thumb.length !== 0 && thumb}
				playing={thumb.length !== 0 && true}
				className='ReactPlayer'
				id={uid}
				controls
				playsInline // Disable download button
				config={{
					file: {
						attributes: { controlsList: "nodownload" },
					},
				}}
				// Disable right click
				onContextMenu={(e) => e.preventDefault()}
				onClickPreview={() => handlePlayInline()}
				onReady={() => handlePlayInline()}
				onPlay={handlePlay}
				width={"100%"}
				height={"auto"}
				
				playIcon={
					<img
						src='assets/Play60.png'
						alt=''
						style={{ width: "40px", height: "40px" }}
					/>
				}
				url={url}
			/>
		</>
	);
}
